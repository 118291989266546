@use 'bootstrap/scss/mixins/breakpoints';
@use 'variables';

@mixin space-default-res($properties) {
  @if type-of($properties) == 'string' {
    $properties: append((), $properties);

    @each $property in $properties {
      #{$property}: 1.15rem;

      @include breakpoints.media-breakpoint-up('lg', variables.$grid-breakpoints) {
        #{$property}: 1.5rem;
      }
    }
  }
}

@mixin square-size-responsive($sm, $md, $lg, $xl: $lg) {
  @include breakpoints.media-breakpoint-down('md', variables.$grid-breakpoints) {
    width: $sm;
    height: $sm;
  }

  @include breakpoints.media-breakpoint-between('md', 'lg', variables.$grid-breakpoints) {
    width: $md;
    height: $md;
  }

  @include breakpoints.media-breakpoint-between('lg', 'xl', variables.$grid-breakpoints) {
    width: $lg;
    height: $lg;
  }

  @include breakpoints.media-breakpoint-up('xl', variables.$grid-breakpoints) {
    width: $xl;
    height: $xl;
  }
}

@mixin icon-size-responsive(
  $sm: variables.$default-mobile-font-size * 1.3077,
  $md: variables.$default-tablet-font-size * 1.3077,
  $lg: variables.$default-font-size * 1.25,
  $xl: $lg
) {
  @include square-size-responsive($sm, $md, $lg, $xl);
}
