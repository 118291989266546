@use 'variables';
@use 'bootstrap/scss/mixins/breakpoints';

.std-accordion {
  &.accordion {
    --bs-accordion-active-bg: hsl(var(--std-select-active-bg));
    --bs-accordion-active-color: hsl(var(--std-select-active-text));
    --bs-accordion-body-padding-x: 0.75rem;
    --bs-accordion-body-padding-y: 0.5rem;
    --bs-accordion-btn-bg: hsl(var(--std-select-active-bg));
    --bs-accordion-btn-color: hsl(var(--std-select-active-text));
    --bs-accordion-btn-color: hsl(var(--std-select-active-text));
    --bs-accordion-btn-icon: var(--std-icon-chevron-down-url); // url('data:image/svg+xml, %3csvg id=%22chevron-down%22 width=%2224%22 height=%2224%22 viewBox=%220 0 24 24%22 fill=%22none%22 stroke=%22%2355596D%22 stroke-width=%22inherit%22 xmlns=%22http://www.w3.org/2000/svg%22%3e%3cpath d=%22M6 9L12 15L18 9%22 stroke=%22inherit%22 stroke-width=%22inherit%22 stroke-linecap=%22round%22 stroke-linejoin=%22round%22 /%3e%3c/svg%3e');
    --bs-accordion-btn-icon-width: 1.5rem;
    --bs-accordion-btn-active-icon: var(--bs-accordion-btn-icon);
    --bs-accordion-btn-focus-box-shadow: none;
    --bs-accordion-btn-padding-x: 0.625rem;
    --bs-accordion-btn-padding-y: 0.625rem;
    --bs-border-radius: 8px;
    --bs-border-width: 0px;

    .accordion-button {
      border-radius: var(--bs-accordion-inner-border-radius);

      &:not(.collapsed) {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    .accordion-body {
      background-color: variables.$gray-light;
      border-bottom-left-radius: var(--bs-border-radius);
      border-bottom-right-radius: var(--bs-border-radius);

      .container-approver {
        background-color: variables.$white-pure;
      }
    }

    .accordion-item {
      border-radius: var(--bs-border-radius);
      margin-bottom: 1rem;
    }
  }
}

.std-gray-light-accordion {
  @extend .std-accordion;
  $padding: 0.625rem;

  &.accordion {
    --bs-accordion-btn-padding-x: #{$padding};
    --bs-accordion-btn-padding-y: #{$padding};
    --bs-accordion-body-padding-y: #{$padding};
    --bs-accordion-body-padding-x: #{$padding};
    --bs-accordion-btn-icon: url('data:image/svg+xml, %3csvg id=%22chevron-down%22 width=%2224%22 height=%2224%22 viewBox=%220 0 24 24%22 fill=%22none%22 stroke=%22%2355596D%22 stroke-width=%22inherit%22 xmlns=%22http://www.w3.org/2000/svg%22%3e%3cpath d=%22M6 9L12 15L18 9%22 stroke=%22inherit%22 stroke-width=%22inherit%22 stroke-linecap=%22round%22 stroke-linejoin=%22round%22 /%3e%3c/svg%3e');
    --bs-accordion-btn-active-icon: var(--bs-accordion-btn-icon);

    .accordion-button {
      background-color: variables.$gray-light;
      color: variables.$black-neutrals;
    }

    .accordion-body {
      padding-top: 0;
    }
  }
}

.std-header {
  @extend .std-accordion;

  background-color: variables.$white-pure;
  padding: 1.25rem 0;

  &.accordion {
    --bs-accordion-body-padding-x: 0px;
    --bs-accordion-body-padding-y: 0px;
    --bs-accordion-btn-icon: var(--std-accordion-btn-icon-url);
    --bs-accordion-btn-active-icon: var(--bs-accordion-btn-icon);

    @include breakpoints.media-breakpoint-between('md', 'lg', variables.$grid-breakpoints) {
      padding: 1.25rem 0;
    }

    @include breakpoints.media-breakpoint-up('lg', variables.$grid-breakpoints) {
      padding: 1.5rem 0;
    }

    .accordion-button {
      @include breakpoints.media-breakpoint-down('md', variables.$grid-breakpoints) {
        &::after {
          transform: scale(1.25);
        }
      }

      * {
        color: hsl(var(--std-select-active-text));
      }
    }

    .accordion-body {
      background-color: white;
    }

    .accordion-item {
      border-radius: var(--bs-accordion-inner-border-radius);

      .accordion-button {
        border-radius: var(--bs-accordion-inner-border-radius);
      }
    }
  }
}

.std-table {
  @extend .std-header;

  &.accordion {
    --bs-border-width: 1px;

    $padding: 0.625rem;
    --bs-accordion-btn-padding-x: #{$padding};
    --bs-accordion-btn-padding-y: 0.75rem;
    --bs-accordion-body-padding-y: #{$padding};
    --bs-accordion-body-padding-x: #{$padding};

    .accordion-button:not(.collapsed) {
      box-shadow: none;
    }
  }
}
