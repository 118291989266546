@use 'bootstrap/scss/mixins/breakpoints';
@use 'variables';
@use 'mixins/utility';

.skin-v4-page-container {
  &.form-page, &.detail-page {
    &.detail-wrap {
      display: flex;
      flex-direction: column;
      height: 100%;
      position: relative;
      padding: 0;

      .detail-header {
        background-color: white;
        width: 100%;

        .icon-back {
          @include utility.icon-size-responsive();
        }
      }

      .detail-form, .section-content {
        background-color: white;
        flex: 1 1 0;
        padding: 2rem;
        border-radius: variables.$border-radius;
        border: 1px solid variables.$gray-anti-flash;

        @include breakpoints.media-breakpoint-down('sm', variables.$grid-breakpoints) {
          padding: 1rem;
          border-radius: unset;
          border: unset;
        }

        .is-invalid {
          border-color: red !important;

          ::ng-deep ng-select.std-skin .ng-select-container {
            border-color: inherit;
          }
        }

        .ng-select.is-invalid {
          ::ng-deep .ng-select-container {
            border-color: red !important;
            padding-right: calc(1.5em + 0.75rem);
            background-position: right calc(0.375em + 0.1875rem) center;
            background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
          }
        }
      }
    }
  }

  &.list-page {
    &.content-field {
      display: flex;
      flex-direction: column;
      height: 100%;

      .content-empty {
        align-items: center;
        display: flex;
        flex-direction: column;
        flex: 1 1 100%;
        justify-content: center;

        &.search-not-found {
          background: variables.$white-pure;
          border-radius: 0 0 variables.$border-radius-input variables.$border-radius-input;
        }
      }

      .dropdown-std .dropdown-menu {
        min-width: 10rem !important;
      }

      .dropdown-std .dropdown-item {
        border-radius: variables.$border-radius-input;
        color: variables.$gray-pantone;
        margin-top: 0px;
        padding: .375rem .5rem;
        transition-property: font-weight, background-color, color;
      }

      .table-panel {
        flex: 1 1 auto;
        background-color: white;
      }

      .datatable-wrapper {
        box-shadow: 0 4px 30px 0 #2e2d740d;
        border-radius: variables.$border-radius-input;
        border-color: transparent;
        overflow-y: hidden;
      }
    }

    input {
      border: 1px solid variables.$gray-anti-flash;
      line-height: 3;
    }
  }

  ::placeholder {
    color: variables.$gray-neutrals;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  :host ::ng-deep {
    .tooltip-arrow {
      display: none;
    }

    .tooltip-2 {
      .tooltip-inner {
        white-space: pre-wrap !important;
        max-width: 35vw !important;
      }
    }
  }

  label {
    color: variables.$black-neutrals;
  }

}
